<template>
  <div>
    <v-card
      flat
      class="mt-5 ghd-border-black"
    >
      <v-form>
        <div
          class="px-3 pt-3"
        >
          <!-- new password -->
          <v-text-field
            v-model="sendData.password"
            :type="isNewPasswordVisible ? 'text' : 'password'"
            :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
            label="Nuova Password"
            outlined
            dense
            hint="Almeno 8 caratteri, una lettera maiuscol e un simbolo"
            persistent-hint
            :rules="[validators.required, validators.passwordValidator]"
            @click:append="isNewPasswordVisible = !isNewPasswordVisible"
          ></v-text-field>

          <!-- confirm password -->
          <v-text-field
            v-model="sendData.password_confirmation"
            :type="isCPasswordVisible ? 'text' : 'password'"
            :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
            label="Conferma Password"
            outlined
            dense
            class="mt-3"
            :rules="[validators.required]"
            @click:append="isCPasswordVisible = !isCPasswordVisible"
          ></v-text-field>

          <v-btn
            color="primary"
            block
            class="mb-4 mt-2"
            @click="onSubmit()"
          >
            Salva
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>

import {
  mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline,
} from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import {
  confirmedValidator,
  passwordValidator,
  required,
} from '@core/utils/validation'
import axios from '@axios'
import { getUserData, useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'

export default {
  setup() {
    const { router } = useRouter()

    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)

    const blankSendData = {
      id: getUserData().id,
      password: null,
      password_confirmation: null,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }
    resetSendData()

    const vm = getCurrentInstance().proxy
    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
      window.location.reload()
    }

    const onSubmit = () => {
      axios.put(`/api/user/${sendData.value.id}`, sendData.value)
        .then(() => {
          logoutUser()
        }).catch(error => {
          console.log(error)
        })
    }

    return {
      isNewPasswordVisible,
      isCPasswordVisible,
      sendData,
      onSubmit,

      getUserData,

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      // Field Validators
      validators: {
        required,
        passwordValidator,
        confirmedValidator,
      },
    }
  },
}
</script>
