<template>
  <div>
    <v-row>
      <v-col class="col-12 col-md-6 offset-md-3">
        <user-change-password />
      </v-col>

      <v-col class="col-12 col-md-6 offset-md-3">
        <v-card
          flat
          class="mt-5 ghd-border-red"
        >
          <v-form>
            <div
              class="px-3 pt-3"
            >
              <v-alert class="bg-red ">
                <span style="color: rgba(255, 255, 255, 1)!important">
                  ATTENZIONE: scegliendo di cancellare il tuo account perderai tutti i tuoi dati e le tue assistenze.
                </span>
              </v-alert>
              <div
                v-if="canRequestDeletingAccount"
              >
                <v-dialog
                  v-model="isDialogVisible"
                  max-width="600"
                >
                  <!-- Dialog Activator -->
                  <template
                    #activator="{ props }"
                  >
                    <v-btn
                      v-bind="props"
                      color="primary"
                      block
                      class="mb-4"
                      @click="isDialogVisible = true"
                    >
                      Rimuovi account
                    </v-btn>
                  </template>

                  <v-card
                    title="Rimozione account"
                    class="pt-5"
                  >
                    <v-card-text>
                      <p>
                        Per confermare l'eliminazione, digita <i>elimina definitivamente</i> nel campo di testo qui sotto.
                      </p>
                      <v-text-field
                        v-model="keyword"
                        label="Campo di testo"
                        outlined
                        dense
                        hint="Necessario scrivere 'elimina definitivamente'"
                        persistent-hint
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />

                      <v-btn
                        color="primary"
                        :disabled="handleDisabledField()"
                        @click="handleGenerateDeleteAccountCode()"
                      >
                        Rimuovi account
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <div v-else>
                <v-text-field
                  v-model="code"
                  label="Codice"
                  outlined
                  dense
                  hint="Inserisci il codice che ti è arrivato alla tua email"
                  persistent-hint
                />
                <v-btn
                  color="primary"
                  block
                  class="mb-4"
                  @click="deleteAccount"
                >
                  Rimuovi definitivamente il mio account
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref, computed, getCurrentInstance,
} from '@vue/composition-api'
import { getUserData, useRouter } from '@core/utils'
import userService from '@/services/userService'
import store from '@/store'
import { initialAbility } from '@/plugins/acl/config'
import UserChangePassword from '@/views/user/userChangePassword.vue'

export default {
  name: 'UserProfile',
  components: { UserChangePassword },
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const {
      getCanRequestDeleteingCode,
      generateDeleteAccountCode,
    } = userService()

    const userData = getUserData()

    const isDialogVisible = ref(false)
    const keyword = ref('')
    const code = ref(null)

    const canRequestDeletingAccount = computed(() => getCanRequestDeleteingCode())

    const checkTimingForDeletingAccount = () => {
      // // se é presente in questo data controllo da data senno non ha senso
      if (canRequestDeletingAccount.value === false) {
        const timer = setInterval(() => {
          // ogni 10 secondi controllo se la data in cui ha richiesta la cancellazione non é passata se lo fosse la funzione cancellerebbe "canRequestDeletingCode" e quindi dovrei riaggiornare la pagina
          store.commit('user/initialiseStoreRequestDeletetingCode')
          if (canRequestDeletingAccount.value) {
            clearInterval(timer)
          }
          console.log('controllo')
        }, 5000)
      }
    }

    const handleGenerateDeleteAccountCode = () => {
      generateDeleteAccountCode({ role: userData.role.toUpperCase(), email: userData.email }).then(() => {
        checkTimingForDeletingAccount()
      })
    }

    checkTimingForDeletingAccount()

    const deleteAccount = () => {
      store.dispatch('user/deleteUser', { user_id: userData.id, code: code.value }).then(() => {
        localStorage.removeItem('accessToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')

        // Reset ability
        vm.$ability.update(initialAbility)

        // Redirect to login page
        router.push({ name: 'auth-login' })
      }).catch(error => {
        console.log(error)
      })
    }

    const handleDisabledField = () => keyword.value !== 'elimina definitivamente'

    return {
      isDialogVisible,
      keyword,
      code,
      canRequestDeletingAccount,
      userData,
      getCanRequestDeleteingCode,
      handleDisabledField,
      deleteAccount,
      handleGenerateDeleteAccountCode,
    }
  },
}
</script>

<style scoped>
.bg-red{
  background: #fc0000;
}
</style>
