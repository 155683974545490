import store from '@/store'
import { PROVINCE } from 'codice-fiscale-js/src/lista-province'
import { COMUNI } from 'codice-fiscale-js/src/lista-comuni'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import { cryptDataIntoLocalStorage } from '@core/utils/crypt'

export default () => {
  const province = ref([])
  const comuni = ref([])

  const generateProvincia = () => {
    const provinceValues = Object.values(PROVINCE)
    const provinceKeys = Object.keys(PROVINCE)
    let index = 0
    provinceKeys.forEach(provinciaCode => {
      province.value[index] = {
        label: provinceValues[index].toUpperCase(),
        value: provinciaCode,
      }
      index += 1
    })
  }
  const generateComune = () => {
    const comuniList = COMUNI
    let index = 0
    comuniList.forEach(comune => {
      comuni.value[index] = {
        province: comune[1],
        label: comune[2],
        value: comune[2],
        code: comune[0],
      }
      index += 1
    })
  }

  const filterCityByProvince = selectedProvince => comuni.value.filter(comune => comune.province === selectedProvince)

  const generateDeleteAccountCode = data => {
    return store.dispatch('user/deleteAccount', data).then(response => {
      localStorage.setItem('exp_date_code_delacc', Date.parse(response.data.data.exp_date)) // setto la data su cui fare il controllo e cambiare lo store
      store.commit('user/setCanRequestDeletingCode', false)
    }).catch(error => {
      console.log(error)
    })
  }

  const getCanRequestDeleteingCode = () => store.getters['user/getCanRequestDeleteingCode']

  const updateUser = userData => {
    store.dispatch('user/updateUser', userData).then(response => {
      cryptDataIntoLocalStorage('userData', JSON.stringify(response.data.data))
    }).catch(() => {
      console.log('error')
    })
  }

  return {
    province,
    comuni,
    updateUser,
    generateProvincia,
    generateComune,
    filterCityByProvince,
    getCanRequestDeleteingCode,
    generateDeleteAccountCode,
  }
}
